export default {
    EVENT_TYPES: [
        {
            value: 0,
            text: "MIRROR_MATCH"
        },
        {
            value: 1,
            text: "GAME_MODE"
        }
    ],
    GAME_MODE_MODIFIER_TYPES: [
        {
            value: 0,
            text: "REGULAR"
        },
        {
            value: 1,
            text: "DOUBLE_MANA"
        },
        {
            value: 2,
            text: "SUDDEN_DEATH"
        },
        {
            value: 3,
            text: "MONSTER_RUSH"
        },
        {
            value: 4,
            text: 'SNOWBALL'
        },
        {
            value: 5,
            text: 'VALENTINES_DAY'
        }
    ],
    PASSIVE_EVENT_TYPES: [
        {
            value: 0,
            text: "Gold Rush"
        },
        {
            value: 1,
            text: "Shop Chest Discount"
        }
    ],
    EVENT_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: 3,
            text: "UNIT"
        },
        {
            value: 4,
            text: "HERO"
        },
        {
            value: 6,
            text: "EMOJI"
        },
        {
            value: 14,
            text: "AVATAR"
        }
    ],
    EVENT_LEADERBOARD_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: 6,
            text: "EMOJI"
        },
        {
            value: 14,
            text: "AVATAR"
        }
    ],
    ARENA_OPTIONS: [
        {
            text: 'Magic Forest',
            value: 0
        },
        {
            text: 'Pirate Beach',
            value: 1
        },
        {
            text: 'Snowy Peaks',
            value: 2
        },
        {
            text: 'Toxic Swamp',
            value: 3
        },
        {
            text: 'Lava Pit',
            value: 4
        },
        {
            text: 'Ocean Floor',
            value: 5
        },
        {
            text: 'Candy Town',
            value: 6
        },
        {
            text: 'Crystal Mine',
            value: 7
        },
        {
            text: 'Royal Castle',
            value: 8
        },
        {
            text: 'Graveyard',
            value: 9
        },
        {
            text: 'Skyrise',
            value: 10
        },
    ],
    INFO_FIELD_ICONS: [
        {
            text: 'Crosses',
            value: 0
        },
        {
            text: 'Cards',
            value: 1
        },
        {
            text: 'Lock',
            value: 2
        },
        {
            text: 'Double Mana',
            value: 3
        },
        {
            text: 'Sudden Death',
            value: 4
        },
        {
            text: 'Monster Rush',
            value: 5
        },
        {
            text: 'Random Rumble',
            value: 6
        },
        {
            text: 'Snowball',
            value: 7
        },
        {
            text: 'Valentines',
            value: 8
        },
        {
            text: 'Gold Rush',
            value: 9
        },
        {
            text: 'Shop Chest Discount',
            value: 10
        },
        {
            text: 'Treasure Hunt',
            value: 11
        },
        {
            text: 'Token',
            value: 12
        },
        {
            text: 'Target',
            value: 13
        }
    ]
}