const REWARD_TYPES = {
    GOLD: 0,
    GEM: 1,
    CHEST: 2,
    UNIT: 3,
    EMOJI: 6
}

const EVENT_TYPES = {
    MIRROR_MATCH: 0,
    CHALLENGE: 1,
    RANDOM_RUMBLE: 2,
    PASSIVE: 3
}

const CHEST_IDS = {
    WOODEN: "VltKoUl2xQJavmKOzvOI",
    SILVER: "qroTswstg3N9raIsUmru",
    GOLD: "TNFw8UrxKBzGUsuwb3Le",
    IRON: "c7IX6YrPw7LDPxhpQ5vr",
    DIAMOND: "Qxi6BVi2nRE1l5IlQq0B",
    LEGENDARY: "Br59Oc9UemJT8aOFiY8B",
    SEASON_PASS_TIER0: "HXOhoMTUhsIBToFY1gjW",
    SEASON_PASS_TIER1: "M00BMH8K1E2rpqk37IPk",
    SEASON_PASS_TIER2: "KFKL2jrHlgXIbXcD41dY",
    SEASON_PASS_TIER3: "16csBloB4KU3s2tKqFkk",
    SEASON_PASS_TIER4: "IwkQEYqnbb8UpIsdSZrW",
    ONE_AD: "gy5iIdK1gkr8zB7s2dHm",
    AD_MEGA: "ZCfc5MF5fKHEAhyQ5Q2L",
    VICTORY: "mdi0fBBIhzWpbclHaM7d",
    SUPPORT: "Zos83NIXQ2fv3RoywyfO",
    SHOP_BIG: "rvULim413LiBIjBK3lVx",
    SHOP_HERO: "f9cjPvL6Nnrm9rD1UpDB",
    SHOP_LARGE: "il6fJ8Bj2etGbcLPrYKQ",
    SHOP_MEGA_EPIC: "6NxPvGFVJx6w31Jh4G2X",
    SHOP_COLOSSAL: "Qhb1vxVj9gKtFfmaENLF"
}

const CHALLENGE_MODIFIERS = {
    REGULAR: 0,
    DOUBLE_MANA: 1,
    SUDDEN_DEATH: 2,
    MONSTER_RUSH: 3,
    SNOWBALL: 4,
    VALENTINES: 5
}

const ICONS = {
    CROSSES: 0,
    CARDS: 1,
    LOCK: 2,
    DOUBLE_MANA: 3,
    SUDDEN_DEATH: 4,
    MONSTER_RUSH: 5,
    RANDOM_RUMBLE: 6,
    SNOWBALL: 7,
    VALENTINES: 8,
    GOLD_RUSH: 9
}

const PASSIVE_EVENT_TYPES = {
    GOLD_RUSH: 0,
    SHOP_CHEST_DISCOUNT: 1,
    EXPRESS_DELIVERY: 2,
    SLASH_CASH: 3
}

export default {
    getPassiveEventSchema: () => {
        return {
            type: EVENT_TYPES.PASSIVE,
            start_date: Date.now(),
            end_date: Date.now() + 2 * 24 * 60 * 60 * 1000,
            passive_event_type: PASSIVE_EVENT_TYPES.GOLD_RUSH,
            threshold: 0,
            event_icon: ICONS.GOLD_RUSH,
            push_notifications: [],
            event_asset: "",
            localizables: {
                popup_header: {},
                popup_subheader: {},
                popup_info: {}
            }
        }
    },
    getSchema: () => {
        return {
            type: EVENT_TYPES.CHALLENGE,
            card_level: 8,
            modifier_type: CHALLENGE_MODIFIERS.DOUBLE_MANA,
            ticket_gem_value: 10,
            arena: 0,
            name: "Double Mana Challenge",
            short_description: "Double the mana. Double the trouble.",
            info_fields: [{ icon: 0, name: {}, text: {} }],
            localizables: { name: {}, short_description: {} },
            start_date: Date.now(),
            end_date: Date.now() + 24 * 60 * 60 * 1000,
            push_notifications: [],
            rewards: {
                "1": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 100
                    }
                ],
                "2": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.SILVER
                    }
                ],
                "3": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 250
                    }
                ],
                "4": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.GOLD
                    }
                ],
                "5": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 500
                    }
                ],
                "6": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.GOLD
                    }
                ],
                "7": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 750
                    }
                ],
                "8": [
                    {
                        type: REWARD_TYPES.UNIT,
                        amount: 3,
                        rarity: 2
                    }
                ],
                "9": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 1000
                    }
                ],
                "10": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.IRON
                    }
                ]
            },
            leaderboard_rewards: [
                {
                    rewardCount: 1,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.LEGENDARY,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 5000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 500
                        }
                    ]
                },
                {
                    rewardCount: 1,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.LEGENDARY,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 4000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 400
                        }
                    ]
                },
                {
                    rewardCount: 1,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.LEGENDARY,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 3000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 300
                        }
                    ]
                },
                {
                    rewardCount: 7,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.SHOP_LARGE,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 2000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 250
                        }
                    ]
                },
                {
                    rewardCount: 10,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.DIAMOND,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 1500
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 200
                        }
                    ]
                },
                {
                    rewardCount: 30,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.SHOP_BIG,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 1000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 150
                        }
                    ]
                },
                {
                    rewardCount: 50,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.IRON,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 500
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 100
                        }
                    ]
                }
            ],
            leaderboard_data: {
                minGameScore: 0,
                leaderboard: []
            }
        }
    },
    getDefaultInfoFields: (modifierType) => {
        switch (modifierType) {
            case CHALLENGE_MODIFIERS.DOUBLE_MANA: 
                return doubleManaRuleTexts.concat(eventsGeneric).concat(eventsPointRuleGeneric)
            case CHALLENGE_MODIFIERS.SUDDEN_DEATH: 
                return suddenDeathRuleTexts.concat(eventsGeneric).concat(eventsPointRuleGeneric)
            case CHALLENGE_MODIFIERS.MONSTER_RUSH:
                return monsterRushRuleTexts.concat(eventsGeneric).concat(eventsPointRuleGeneric)
            case CHALLENGE_MODIFIERS.VALENTINES:
                return valentinesRuleTexts.concat(eventsGeneric).concat(valentinesPointRule)
            case CHALLENGE_MODIFIERS.SNOWBALL:
                return snowballRuleTexts.concat(eventsGeneric).concat(snowballPointRule)
            default:
                return []
        }
    },
    getDefaultLocalizables: (modifierType) => {
        switch (modifierType) {
            case CHALLENGE_MODIFIERS.DOUBLE_MANA: 
                return doubleManaLocaizables
            case CHALLENGE_MODIFIERS.SUDDEN_DEATH: 
                return suddenDeathLocaizables
            case CHALLENGE_MODIFIERS.MONSTER_RUSH:
                return monsterRushLocaizables
            case CHALLENGE_MODIFIERS.SNOWBALL: 
                return snowballLocalizables
            case CHALLENGE_MODIFIERS.VALENTINES: 
                return valientinesLocalizables
            default:
                return null
        }
    },
    getRandomRumbleDefaultRules: () => {
        return randomRumbleDefaultTexts
    },
    getPassiveEventTexts: (eventType) => {
        switch (eventType) {
            case PASSIVE_EVENT_TYPES.GOLD_RUSH:
                return goldRushLocalizables
            case PASSIVE_EVENT_TYPES.SHOP_CHEST_DISCOUNT:
                return chestDiscountLocalizables
            default:
                return goldRushLocalizables
        }
    }
}


//#region Passive Events

const goldRushLocalizables = {
    popup_header: {
        en: "Gold Rush",
        de: "Goldrausch",
        fr: "Ruée vers l'or",
        es: "Fiebre del oro",
        it: "Corsa all'oro",
        pt: "Corrida do Ouro",
        tr: "Altına Hücum"
    }, 
    popup_info: {
        en: "",
        de: "",
        fr: "",
        es: "",
        it: "",
        pt: "",
        tr: ""
    },
    popup_subheader: {
        en: "Receive up to <color=#FFF820>10000</color> Bonus Gold during the event by playing PvP battles.",
        de: "Erhalte während des Events bis zu <color=#FFF820>10.000</color> Bonusgold, indem du PvP-Schlachten bestreitest.",
        fr: "Recevez jusqu'à <color=#FFF820>10 000</color> or bonus pendant l'événement en participant à des combats JcJ.",
        es: "Consigue hasta <color=#FFF820>10000</color> de oro extra durante el evento jugando batallas PvP.",
        it: "Ricevi fino a <color=#FFF820>10.000</color> monete d'oro bonus durante l'evento partecipando a battaglie PvP.",
        pt: "Receba até <color=#FFF820>10.000</color> de Ouro de Bônus durante o evento jogando batalhas PvP.",
        tr: "PvP savaşları oynayarak etkinlik sırasında en fazla 10.000 Bonus Altın kazan."
    }
}

const chestDiscountLocalizables = {
    popup_header: {
        en: "Chest Discount",
        de: "Truhen-Rabatt",
        fr: "Réduction de coffre",
        es: "Descuento en cofres",
        it: "Sconto sui forzieri",
        pt: "Desconto de Baú",
        tr: "Sandık İndirimi"
    }, 
    popup_info: {
        en: "<color=#FFF820>+20%</color>",
        de: "<color=#FFF820>+20%</color>",
        fr: "<color=#FFF820>+20%</color>",
        es: "<color=#FFF820>+20%</color>",
        it: "<color=#FFF820>+20%</color>",
        pt: "<color=#FFF820>+20%</color>",
        tr: "<color=#FFF820>+20%</color>"
    },
    popup_subheader: {
        en: "All shop chests will be discounted for a limited time!",
        de: "Alle Shop-Truhen werden für eine begrenzte Zeit vergünstigt sein!",
        fr: "Tous les coffres de la boutique seront à prix réduit pour une durée limitée !",
        es: "¡Todos los cofres de la tienda estarán rebajados por tiempo limitado!",
        it: "Tutti i forzieri del negozio saranno scontati per un periodo di tempo limitato!",
        pt: "Todos os baús da loja terão um desconto por um tempo limitado!",
        tr: "Tüm mağaza sandıkları sınırlı bir süre için indirime sokulacaktır!"
    }
}

//#endregion

//#region Challenge Events

const doubleManaLocaizables = {
    name: {
        en: "Double Mana Challenge",
        de: "Doppeltes-Mana-Herausforderung",
        es: "Desafío de doble de maná",
        fr: "Défi double mana",
        it: "Sfida del doppio mana",
        pt: "Desafio de Dobro de Mana",
        tr: "Çifte Mana Meydan Okuması"
    },
    short_description: {
        en: "Double the mana. Double the trouble.",
        de: "Doppelt so viel Mana. Doppelt so viel Ärger.",
        es: "Duplica el maná. Duplica el problema.",
        fr: "Doublez le mana. Doublez les ennuis.",
        it: "Raddoppia il mana. Raddoppia i problemi.",
        pt: "Dobro de mana, confusão em dobro.",
        tr: "Manayı ikiye katla. Belayı ikiye katla."
    }
}

const monsterRushLocaizables = {
    name: {
        en: "Monster Rush Challenge",
        de: "Monsterrausch-Herausforderung",
        es: "Desafío rápido de monstruos",
        fr: "Défi de la ruée vers les monstres",
        it: "Sfida di frenesia dei mostri",
        pt: "Desafio Corrida de Monstros",
        tr: "Canavar Hücumu Mücadelesi"
    },
    short_description: {
        en: "Ready, set, rush. Monsters gotta go fast.",
        de: "Auf die Plätze, fertig, los. Monster müssen schnell sein.",
        es: "Preparados, listos… ¡rápido! Los monstruos son rápidos.",
        fr: "Prêts, partez, précipitez-vous. Les monstres doivent aller vite.",
        it: "Pronti, partenza, via. I mostri sono in frenesia.",
        pt: "Preparar, apontar... corram! Os monstros serão rápidos.",
        tr: "Gez, göz, hücum. Canavarların hızlı gitmesi gerekli."
    }
}

const suddenDeathLocaizables = {
    name: {
        en: "Sudden Death Challenge",
        de: "Sudden-Death-Herausforderung",
        es: "Desafio de Morte Repentina",
        fr: "Défi de la mort subite",
        it: "Sfida di morte improvvisa",
        pt: "Desafío de muerte súbita",
        tr: "Ani Ölüm Mücadelesi"
    },
    short_description: {
        en: "One life only. No mistakes allowed.",
        de: "Nur ein Leben. Keine Fehler machen.",
        es: "Solo una vida. No se permiten errores.",
        fr: "Une seule vie. Aucune erreur n'est permise.",
        it: "Una sola vita. Nessun errore consentito.",
        pt: "Somente uma vida. Não é permitido errar.",
        tr: "Yalnızca tek can. Hiçbir hataya izin verilmez."
    }
}

const snowballLocalizables = {
    name: {
        en: "Snowball Fight",
        de: "Schneeballschlacht",
        es: "Lucha de bolas de nieve",
        fr: "Bataille de boules de neige",
        it: "Battaglia a palle di neve",
        pt: "Luta de Bola de Neve",
        tr: "Kartopu Savaşı"
    },
    short_description: {
        en: "It's a huge snowball fight!",
        de: "Das ist eine riesige Schneeballschlacht!",
        es: "¡Es una gran pelea de bolas de nieve!",
        fr: "C'est une énorme bataille de boules de neige !",
        it: "È un'enorme battaglia a palle di neve!",
        pt: "É uma enorme luta de bola de neve!",
        tr: "Bu büyük bir kartopu savaşı!"
    }
}

const valientinesLocalizables = {
    name: {
        en: "Heart Attack Challenge",
        de: "Herzinfarkt-Herausforderung",
        es: "Desafío de Ataque al Corazón",
        fr: "Défi Crise cardiaque",
        it: "Sfida di attacco di cuore",
        pt: "Desafio do Ataque do Coração",
        tr: "Kalp Krizi Mücadelesi"
    },
    short_description: {
        en: "It's a huge heart attack challenge!",
        de: "Es ist eine riesige Herzinfarkt-Herausforderung!",
        es: "¡Es un gran desafío de ataque al corazón!",
        fr: "C'est un énorme défi crise cardiaque !",
        it: "È una grande sfida di attacco di cuore!",
        pt: "É um enorme desafio de ataque do coração!",
        tr: "Bu büyük bir kalp krizi mücadelesi!"
    }
}

const doubleManaRuleTexts = [
    {
        icon: ICONS.DOUBLE_MANA,
        name: {
            en: "Double Mana Challenge",
            de: "Doppeltes-Mana-Herausforderung",
            es: "Desafío de doble de maná",
            fr: "Défi double mana",
            it: "Sfida del doppio mana",
            pt: "Desafio de Dobro de Mana",
            tr: "Çifte Mana Mücadelesi"
        },
        text: {
            en: "Create your own deck and play in battles with Double Mana (and double the trouble)! 3 losses and you’re out, but you’ll get a chance to reset your losses and keep your progress.",
            de: "Erstelle dein eigenes Deck und spiele in Kämpfen mit doppeltem Mana (und doppeltem Ärger)! Bei 3 Niederlagen bist du raus, aber du hast die Chance, deine Niederlage zurückzusetzen und deinen Fortschritt zu behalten.",
            es: "¡Crea tu propia baraja y juega en batallas con el doble de maná (y el doble de problemas)! Tres derrotas y estás fuera, pero podrás restablecer tus derrotas y mantener tu progreso.",
            fr: "Créez votre propre deck et participez à des batailles avec double mana (et deux fois plus d'ennuis) ! 3 pertes et vous êtes éliminé, mais vous aurez la possibilité de réinitialiser vos pertes et de conserver votre progression.",
            it: "Crea il tuo mazzo e gioca in battaglie con doppio mana (e doppi problemi)! Perdi 3 volte e sei fuori, ma avrai la possibilità di azzerare le tue perdite e mantenere i tuoi progressi.",
            pt: "Crie seu próprio baralho e participe de batalhas com o dobro de mana (e confusão em dobro)! Com 3 derrotas, você é eliminado, mas terá uma chance de zerar as derrotas e manter o progresso.",
            tr: "Çifte Mana ile kendi desteni oluştur ve savaşlarda oyna (ve belayı ikiye katla)! 3 yenilgiden sonra oyun dışı kalırsın ama yenilgilerini sıfırlama ve ilerlemene devam etme şansına sahip olursun."
        }
    }
]

const monsterRushRuleTexts = [
    {
        icon: ICONS.MONSTER_RUSH,
        name: {
            en: "Monster Rush Challenge",
            de: "Monsterrausch-Herausforderung",
            es: "Desafío rápido de monstruos",
            fr: "Défi de la ruée vers les monstres",
            it: "Sfida di frenesia dei mostri",
            pt: "Desafio de Corrida de Monstros",
            tr: "Canavar Hücumu Mücadelesi"
        },
        text: {
            en: "Create your own deck and play in battles where all monsters are a lot faster. 3 losses and you’re out, but you’ll get a chance to reset your losses and keep your progress.",
            de: "Erstelle dein eigenes Deck und spiele in Schlachten, in denen alle Monster viel schneller sind. Bei 3 Niederlagen bist du raus, aber du hast die Chance, deine Niederlagen zurückzusetzen und deinen Fortschritt zu behalten.",
            es: "Crea tu propia baraja y juega en batallas donde todos los monstruos son mucho más rápidos. Tres derrotas y estás fuera, pero podrás restablecer tus derrotas y mantener tu progreso.",
            fr: "Créez votre propre deck et jouez dans des batailles où tous les monstres sont beaucoup plus rapides. 3 pertes et vous êtes éliminé, mais vous aurez une chance de réinitialiser vos pertes et de conserver votre progression.",
            it: "Crea il tuo mazzo e gioca in battaglie in cui tutti i mostri sono molto più veloci. Nessun errore consentito. Perdi 3 volte e sei fuori, ma avrai la possibilità di azzerare le tue perdite e mantenere i tuoi progressi.",
            pt: "Crie seu próprio baralho e participe de batalhas em que todos os monstros são muito mais rápidos. Com 3 derrotas, você é eliminado, mas terá uma chance de zerar as derrotas e manter o progresso.",
            tr: "Kendi desteni oluştur ve tüm canavarların çok daha hızlı olduğu savaşlarda oyna. 3 yenilgiden sonra oyun dışı kalırsın ama yenilgilerini sıfırlama ve ilerlemene devam etme şansına sahip olacaksın."
        }
    }
]

const suddenDeathRuleTexts = [
    {
        icon: ICONS.SUDDEN_DEATH,
        name: {
            en: "Sudden Death Challenge",
            de: "Sudden-Death-Herausforderung",
            es: "Desafío de muerte súbita",
            fr: "Défi de la mort subite",
            it: "Sfida di morte improvvisa",
            pt: "Desafio de Morte Repentina",
            tr: "Ani Ölüm Mücadelesi"
        },
        text: {
            en: "Create your own deck and play in battles where you only have one life. No mistakes allowed. 3 losses and you’re out, but you’ll get a chance to reset your losses and keep your progress.",
            de: "Erstelle dein eigenes Deck und spiele in Schlachten, in denen du nur ein Leben hast. Mache keinen Fehler. Nach 3 Niederlagen bist du raus, aber du hast die Chance, deine Niederlagen zurückzusetzen und deinen Fortschritt zu behalten.",
            es: "Crea tu propia baraja y juega en batallas donde solo tienes una vida. No se permiten errores. Tres derrotas y estás fuera, pero podrás restablecer tus derrotas y mantener tu progreso.",
            fr: "Créez votre propre deck et jouez dans des batailles où vous n'avez qu'une seule vie. Aucune erreur n'est permise. 3 pertes et vous êtes éliminé, mais vous aurez la possibilité de réinitialiser vos pertes et de conserver votre progression.",
            it: "Crea il tuo mazzo e gioca in battaglie in cui hai solo una vita. Nessun errore consentito. Perdi 3 volte e sei fuori, ma avrai la possibilità di azzerare le tue perdite e mantenere i tuoi progressi.",
            pt: "Crie seu próprio baralho e participe de batalhas em que você tem somente uma vida. Não é permitido errar. Com 3 derrotas, você é eliminado, mas terá uma chance de zerar as derrotas e manter o progresso.",
            tr: "Kendi desteni oluştur ve yalnızca tek bir cana sahip olduğun savaşlarda oyna. 3 yenilgiden sonra oyun dışı kalırsın ama yenilgilerini sıfırlama ve ilerlemene devam etme şansına sahip olacaksın."
        }
    }
]

const valentinesRuleTexts = [
    {
        icon: ICONS.VALENTINES,
        name: {
            en: "Heart Attack Challenge",
            de: "Herzinfarkt-Herausforderung",
            es: "Desafío de Ataque al Corazón",
            fr: "Défi Crise cardiaque",
            it: "Sfida di attacco di cuore",
            pt: "Desafio do Ataque do Coração",
            tr: "Kalp Krizi Mücadelesi"
        },
        text: {
            en: "Play this special one time event and keep hittin' em hearts! Each merge sends a heart to the random opponent unit which causes a short stun, get ready to dodge them in time!",
            de: "Spiele dieses besondere, einmalige Ereignis und schlage die Anderen mit Herzen! Jede Fusion sendet ein Herz an eine zufällige gegnerische Einheit, die eine kurze Betäubung verursacht. Mach dich bereit, ihnen rechtzeitig auszuweichen!",
            es: "¡Juega a este evento especial de una sola vez y sigue lanzándoles corazones! Cada combinación envía un corazón a la unidad adversaria aleatoria que provoca un breve aturdimiento, ¡prepárate para esquivarlos a tiempo!",
            fr: "Jouez à cet événement spécial unique et visez vos ennemis en plein cœur ! Chaque fusion envoie un cœur à une unité adverse aléatoire qui provoque un court étourdissement, préparez-vous à les esquiver à temps !",
            it: "Gioca a questo evento speciale unico e continua a colpire con cuori! Ogni unione lancia un cuore a un'unità avversaria a caso che provoca un breve stordimento, preparati a schivarle in tempo!",
            pt: "Jogue este especial uma vez e continue acertando os corações! Cada combinação envia um coração para uma unidade adversária aleatória que causa um atordoamento curto, prepare-se para esquivá-la a tempo!",
            tr: "Bu özel ve tek seferlik etkinliği oyna ve onları kalpler ile vurmaya devam et! Her birleşme, rakibin rastgele bir birimine kalp gönderir ve bu da onların kısa bir süre sersemlemesine neden olur, kalpleri zamanında atlarmaya hazır ol!"
        }
    }
]

const snowballRuleTexts = [
    {
        icon: ICONS.SNOWBALL,
        name: {
            en: "Snowball Fight",
            de: "Schneeballschlacht",
            es: "Lucha de bolas de nieve",
            fr: "Bataille de boules de neige",
            it: "Battaglia a palle di neve",
            pt: "Luta de Bola de Neve",
            tr: "Kartopu Savaşı"
        },
        text: {
            en: "Play this special one time event and keep hittin' em snowballs! Each merge sends a snowball to the random opponent unit which causes a short stun get ready to dodge them in time!",
            de: "Spiele dieses besondere, einmalige Ereignis und schlage die Anderen mit Schneebällen! Jede Fusion sendet einen Schneeball an eine zufällige gegnerische Einheit, die eine kurze Betäubung verursacht. Mach dich bereit, ihnen rechtzeitig auszuweichen!",
            es: "¡Juega a este evento especial de una sola vez y sigue lanzándoles bolas de nieve! Cada combinación envía una bola de nieve a la unidad adversaria aleatoria que provoca un breve aturdimiento, ¡prepárate para esquivarlas a tiempo!",
            fr: "Jouez à cet événement spécial unique et visez vos ennemis en plein cœur ! Chaque fusion envoie un cœur à une unité adverse aléatoire qui provoque un court étourdissement, préparez-vous à les esquiver à temps !",
            it: "Jouez à cet événement spécial unique et faites pleuvoir les boules de neige ! Chaque fusion envoie une boule de neige à une unité adverse aléatoire qui provoque un court étourdissement, préparez-vous à les esquiver à temps !",
            pt: "Jogue este especial uma vez e continue acertando bolas de neve! Cada combinação envia uma bola de neve para uma unidade adversária aleatória que causa um atordoamento curto, prepare-se para esquivá-la a tempo!",
            tr: "Bu özel ve tek seferlik etkinliği oyna ve onları kar toplarıyla vurmaya devam et! Her birleşme, rakibin rastgele bir birimine kar topu fırlatır ve bu, onların kısa bir süre sersemlemesine neden olur. Kar toplarından zamanında kaçmaya hazır ol!"
        }
    }
]


const snowballPointRule = [
    {
        icon: ICONS.LOCK,
        name: {
            en: "Get the points, get the rewards",
            de: "Hol dir die Punkte, hol dir die Prämien",
            es: "Consigue los puntos y recibe las recompensas",
            fr: "Obtenez les points, obtenez les récompenses",
            it: "Ottieni punti, ottieni premi",
            pt: "Ganhe pontos, ganhe recompensas",
            tr: "Puanları al, ödülleri kap"
        },
        text: {
            en: "You get 1000 points for each victory. Each life left is 50 points. At the end of battle, each monster on your opponent's side counts as 2 points. Also, each snowball you hit counts as 2 points as well.",
            de: "Für jeden Sieg erhältst du 1000 Punkte. Jedes verbleibende Leben ist 50 Punkte wert. Am Ende des Kampfes zählt jedes Monster auf der Seite deines Gegners 2 Punkte. Außerdem zählt jeder Schneeball, den du triffst, ebenfalls 2 Punkte.",
            es: "Você recebe 1.000 pontos por cada vitória. cada vida restante equivale a 50 pontos. Ao final da batalha, cada monstro do lado de seu adversário vale 2 pontos. Além disso, cada bola de neve que você acerta também vale 2 pontos.",
            fr: "Vous obtenez 1 000 points pour chaque victoire. Chaque vie restante équivaut à 50 points. À la fin de la bataille, chaque monstre du côté de votre adversaire compte pour 2 points. De même, chaque boule de neige que vous touchez compte également pour 2 points.",
            it: "Ottieni 1000 punti per ogni vittoria. Ogni vita rimasta dona 50 punti. Alla fine della battaglia, ogni mostro dalla parte del tuo avversario conta come 2 punti. Inoltre, ogni palla di neve che colpisci conta come 2 punti.",
            pt: "Obtienes 1000 puntos por cada victoria. Cada vida restante son 50 puntos. Al final de la batalla, cada monstruo del bando contrario cuenta como 2 puntos. Además, cada bola de nieve que golpees cuenta también como 2 puntos.",
            tr: "Her zafer için 1.000 puan kazanırsın. Geri kalan her can, 50 puandır. Savaşın sonunda, rakibinin tarafındaki her canavar 2 puan sayılır. Ayrıca, vurduğun her kar topu da 2 puan sayılır."
        }
    },
]


const eventsGeneric = [
    {
        icon: ICONS.CROSSES,
        name: {
            en: "3 losses and you’re out!",
            de: "3 Niederlagen und du bist raus!",
            es: "¡Tres derrotas y estás fuera!",
            fr: "3 pertes et vous êtes éliminé !",
            it: "Perdi 3 volte e sei fuori!",
            pt: "Com 3 derrotas, você é eliminado!",
            tr: "3 yenilgiden sonra oyun dışı kalırsın!"
        },
        text: {
            en: "Play until you reach 3 losses. Buying back into the challenge resets all losses and lets you continue from where you are. Try to collect all of the one-time challenge rewards!",
            de: "Spiele, bis du 3 Niederlagen erreicht hast. Wenn du die Herausforderung wieder aufnimmst, werden alle Niederlagen zurückgesetzt und du kannst von dort aus weitermachen, wo du warst. Versuche, alle einmaligen Prämien der Herausforderung zu sammeln!",
            es: "Juega hasta que pierdas 3 veces. Al volver a comprar para acceder al desafío, se restablecen todas las derrotas y podrás continuar desde donde estés. ¡Intenta conseguir todas las recompensas del desafío único!",
            fr: "Jouez jusqu'à ce que vous atteigniez 3 pertes. Revenir dans le défi réinitialise toutes les pertes et vous permet de continuer à partir de là où vous êtes. Essayez de collecter toutes les récompenses du défi unique !",
            it: "Gioca finché non perdi 3 volte. Riacquistare la sfida azzera tutte le perdite e ti consente di continuare da dove sei arrivato. Prova a ottenere tutti i premi di questa sfida una tantum!",
            pt: "Jogue até ter 3 derrotas. Comprar sua volta ao desafio zera todas as derrotas, e você pode continuar de onde parou. Tente resgatar todas as recompensas únicas do desafio!",
            tr: "3 yenilgiye ulaşana dek oyna. Meydan okumaya tekrar girmek tüm yenilgileri sıfırlar ve bulunduğun yerden devam etmene olanak sağlar. Tek seferlik mücadele ödüllerinin tümünü toplamaya çalış!"
        }
    },
    {
        icon: ICONS.CARDS,
        name: {
            en: "Battle on an even playing field!",
            de: "Kämpfe auf gleicher Augenhöhe!",
            es: "¡Lucha en un terreno de juego equilibrado!",
            fr: "Battez-vous sur un terrain de jeu égal !",
            it: "Combatti su un campo di gioco equo!",
            pt: "Batalhe em um campo equilibrado!",
            tr: "Adil bir oyun alanında savaş!"
        },
        text: {
            en: "All units and heroes are level 8.",
            de: "Alle Einheiten und Helden sind auf Stufe 8.",
            es: "Todas las unidades y héroes tienen el nivel 8.",
            fr: "Toutes les unités et tous les héros sont de niveau 8.",
            it: "Tutte le unità e gli eroi sono di livello 8.",
            pt: "Todas as unid. e heróis estão no nvl. 8.",
            tr: "Tüm birimler ve kahramanlar 8. seviyede."
        }
    }
]

const eventsPointRuleGeneric = [
    {
        icon: ICONS.LOCK,
        name: {
            en: "Get the points, get the rewards",
            de: "Hol dir die Punkte, hol dir die Prämien",
            es: "Consigue los puntos y recibe las recompensas",
            fr: "Obtenez les points, obtenez les récompenses",
            it: "Ottieni punti, ottieni premi",
            pt: "Ganhe pontos, ganhe recompensas",
            tr: "Puanları kazan, ödülleri kazan"
        },
        text: {
            en: "You get 1000 points for each victory. Each life left is 50 points. Monsters on your opponent’s side when the battle ends are 2 points each. Make it to the leaderboard to get extra rewards.",
            de: "Du bekommst 1000 Punkte für jeden Sieg. Jedes verbleibende Leben ist 50 Punkte wert. Monster auf der Seite deines Gegners, wenn der Kampf endet, bringen jeweils 2 Punkte. Wenn du es in die Bestenliste schaffst, erhältst du zusätzliche Prämien.",
            fr: "Vous obtenez 1 000 points pour chaque victoire. Chaque vie restante équivaut à 50 points. Les monstres qui se trouvent du côté de votre adversaire à la fin de la bataille valent 2 points chacun. Atteignez le classement pour obtenir des récompenses supplémentaires.",
            it: "Ottieni 1000 punti per ogni vittoria. Ogni vita rimasta vale 50 punti. I mostri dalla parte del tuo avversario quando la battaglia finisce valgono 2 punti ciascuno. Entra in classifica per ottenere premi extra.",
            es: "Recibirás 1000 puntos por cada victoria. Cada vida que te quede equivale a 50 puntos. Recibirás 2 puntos por cada monstruo en el bando de tu oponente cuando termine la batalla. Entra en la tabla de clasificación para conseguir recompensas extra.",
            pt: "Você ganha 1.000 pontos por vitória. Cada vida restante vale 50 pontos. Os monstros no lado adversário ao término da batalha valem 2 pontos cada. Conquiste uma posição no placar para ganhar recompensas adicionais.",
            tr: "Her zafer için 1.000 puan kazanırsın. Geri kalan her can, 50 puandır. Savaş sona erdiğinde rakibinin tarafındaki canavarların her biri 2 puandır. Ekstra ödüller kazanmak için lider sıralamasında yüksel."
        }
    },
]

const valentinesPointRule = [
    {
        icon: ICONS.LOCK,
        name: {
            en: "Get the points, get the rewards",
            de: "Hol dir die Punkte, hol dir die Prämien",
            es: "Consigue los puntos y recibe las recompensas",
            fr: "Obtenez les points, obtenez les récompenses",
            it: "Ottieni punti, ottieni premi",
            pt: "Ganhe pontos, ganhe recompensas",
            tr: "Puanları al, ödülleri kap"
        },
        text: {
            en: "You get 1000 points for each victory. Each life left is 50 points. At the end of battle, each monster on your opponent's side counts as 2 points. Also, each heart you hit counts as 2 points as well.",
            de: "Für jeden Sieg erhältst du 1000 Punkte. Jedes verbleibende Leben ist 50 Punkte wert. Am Ende des Kampfes zählt jedes Monster auf der Seite deines Gegners 2 Punkte. Außerdem zählt jedes Herz, das trifft, ebenfalls 2 Punkte.",
            es: "Obtienes 1000 puntos por cada victoria. Cada vida restante son 50 puntos. Al final de la batalla, cada monstruo del bando contrario cuenta como 2 puntos. Además, cada corazón que golpees cuenta también como 2 puntos.",
            fr: "Vous obtenez 1 000 points pour chaque victoire. Chaque vie restante équivaut à 50 points. À la fin de la bataille, chaque monstre du côté de votre adversaire compte pour 2 points. De même, chaque cœur que vous touchez compte également pour 2 points.",
            it: "Ottieni 1000 punti per ogni vittoria. Ogni vita rimasta dona 50 punti. Alla fine della battaglia, ogni mostro dalla parte del tuo avversario conta come 2 punti. Inoltre, ogni cuore che colpisci conta come 2 punti.",
            pt: "Você recebe 1.000 pontos por cada vitória. cada vida restante equivale a 50 pontos. Ao final da batalha, cada monstro do lado de seu adversário vale 2 pontos. Além disso, cada coração que você acerta também vale 2 pontos.",
            tr: "Her zafere karşılık 1000 puan alırsın. Geriye kalan her can 50 puandır. Savaşın sonunda, rakibinin tarafındaki her canavar 2 puan sayılır. Ayrıca, darbe indirdiğin her kalp de 2 puan sayılır."
        }
    },
]

//#endregion

//#region Random Rumble

const randomRumbleDefaultTexts = [
    {
        icon: ICONS.RANDOM_RUMBLE,
        name: {
            en: "Random Rumble",
            de: "Zufälliger Rumble",
            es: "Rumble aleatorio",
            fr: "Rumble aléatoire",
            it: "Rumble casuale",
            pt: "Pancadaria Aleatória",
            tr: "Rastgele Rumble"
        },
        text: {
            en: "Go on a draft run to receive amazing rewards!",
            de: "Nimm an einem Auswahlverfahren teil und erhalte tolle Belohnungen!",
            es: "¡Participa en una carrera de reclutamiento para recibir recompensas increíbles!",
            fr: "Participez à un tirage spécial pour recevoir pour recevoir des récompenses géniales !",
            it: "Partecipa a un gioco speciale per ricevere fantastici premi!",
            pt: "Parta em uma corrida preparatória para receber recompensas incríveis!",
            tr: "Muhteşem ödüller almak için taslak seriye başla!"
        }
    },
    {
        icon: ICONS.CROSSES,
        name: {
            en: "3 losses and you’re out!",
            de: "3 Niederlagen und du bist raus!",
            es: "¡Tres derrotas y estás fuera!",
            fr: "3 pertes et vous êtes éliminé !",
            it: "Perdi 3 volte e sei fuori!",
            pt: "Com 3 derrotas, você é eliminado!",
            tr: "3 yenilgiden sonra oyun dışı kalırsın!"
        },
        text: {
            en: "Play until you reach 3 losses. Re-joining the event resets all losses and progress.",
            de: "Spiele, bis du 3 Niederlagen erlitten hast. Wenn du das Event erneut betrittst, werden alle Verluste und Fortschritte zurückgesetzt.",
            es: "Juega hasta que alcances 3 derrotas. Si vuelves a participar en el evento, se restablecerán todas las derrotas y el progreso.",
            fr: "Jouez jusqu'à atteindre 3 défaites. Rejoindre l'événement réinitialise les défaites et la progression.",
            it: "Gioca fino a raggiungere 3 sconfitte. Partecipare nuovamente all'evento riazzera tutte le perdite e i progressi.",
            pt: "Jogue até alcançar 3 derrotas. Participar novamente do evento reinicia todas as perdas e o progresso.",
            tr: "3 adet yenilgiye ulaşana dek oyna. Etkinliğe yeniden katılmak, tüm yenilgileri ve ilerlemeyi sıfırlar."
        }
    },
    {
        icon: ICONS.CARDS,
        name: {
            en: "Battle on an even playing field!",
            de: "Kämpfe auf gleicher Augenhöhe!",
            es: "¡Lucha en un terreno de juego equilibrado!",
            fr: "Battez-vous sur un terrain de jeu égal !",
            it: "Combatti su un campo di gioco equo!",
            pt: "Batalhe em um campo equilibrado!",
            tr: "Adil bir oyun alanında savaş!"
        },
        text: {
            en: "All units and heroes are level 8. Each deck consists of 2 legendaries and 3 common, rare or epic cards. You can have 3 legendaries with a small chance.",
            de: "Alle Einheiten und Helden sind auf Stufe 8. Jedes Deck besteht aus 2 legendären Karten und 3 gewöhnlichen, seltenen oder epischen Karten. Du hast eine kleine Chance auf 3 Legenden.",
            es: "Todas las unidades y héroes son de nivel 8. Cada baraja consta de 2 cartas legendarias y 3 comunes, raras o épicas. Puedes tener 3 legendarias con una pequeña probabilidad.",
            fr: "Toutes les unités et tous les héros sont de niveau 8. Chaque deck est composé de 2 cartes légendaires et 3 communes, rares ou épiques. Vous pouvez avoir 3 légendaires avec un peu de chance.",
            it: "Tutte le unità e gli eroi sono di livello 8. Ogni mazzo è composto da 2 carte leggendarie e 3 carte comuni, rare o epiche. Puoi trovare 3 leggendari con una piccola possibilità.",
            pt: "Todas as unidades e heróis são níveis 8. Cada baralho é formado por 2 cartas lendárias e 3 comuns, raras ou épicas. Você tem uma pequena chance de ter 3 lendários.",
            tr: "Tüm birimler ve kahramanlar 8. seviyede. Her deste, 2 efsanevi ve 3 olağan, nadir veya destansı karttan oluşur. Küçük bir şansla 3 efsanevi karta sahip olabilirsin."
        }
    }
]

//#endregion